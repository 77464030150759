import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col, Table } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HSRGuidesCombatandExploPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Combat and exploration</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_combat.png"
            alt="Combat and exploration"
          />
        </div>
        <div className="page-details">
          <h1>Combat and exploration</h1>
          <h2>
            Information about the combat and exploration in Honkai: Star Rail.
          </h2>
          <p>
            Last updated: <strong>24/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Exploration" />
        <p>
          Players are able to roam around and explore the various areas of the
          Honkai: Star Rail map for treasure chests, enemies, puzzles, and more.
          However, each area is its own separate zone, so there will be a
          loading screen when traveling between different areas in the
          overworld.{' '}
        </p>
        <p>
          When exploring the world, you will select one character out of the
          four in your team to be your active character. The active character is
          the one on screen that you control. You can switch the active
          character anytime when you are outside of combat.
        </p>
        <p>
          Outside of the town areas, there will be enemies roaming around the
          map that will aggro and chase you if you get too close to them.{' '}
        </p>
        <h5>Interactive Map</h5>
        <p>
          If you're struggling with finding all the Chests or just navigating
          some maps, you can use the official{' '}
          <a
            href="https://act.hoyolab.com/sr/app/interactive-map/"
            target="_blank"
            rel="noreferrer"
          >
            Interactive Map
          </a>{' '}
          created by the developers.
        </p>
        <h5>Initiating combat</h5>
        <p>
          To initiate combat in the overworld,{' '}
          <strong>approach an enemy and use your Basic Attack ability</strong>.{' '}
        </p>
        <p>
          Some{' '}
          <strong>
            Character’s Technique ability can also be used to initiate combat{' '}
          </strong>
          and when used to do so, will cause the Character to perform a special
          attack at the start of combat.{' '}
          <strong>
            Note that using a Character’s Technique ability to initiate combat
            will consume a Technique charge
          </strong>
          . You can store 3 Technique charges initially. However, the maximum
          amount of Technique charges that you can store will increase as you
          level up your Trailblaze Level. Technique charges can be restored by
          breaking purple containers in the overworld. Breaking one of these
          containers will restore 2 Technique charges. These containers can
          generally be found near fast travel points (i.e. Space Anchors and
          Calyx entrances) that are outside of the city zones.
        </p>
        <p>
          Combat can also be initiated when an enemy that is chasing you manages
          to catch up with you. If you fail to hit them with either your Basic
          Attack or Technique ability before they make contact with you,{' '}
          <strong>then you will enter combat in the “Ambushed” state</strong>.
          In this state, the enemy will get to act before you do.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Combat" />
        <p>
          Honkai: Star Rail utilizes a JRPG style turn-based combat system. You
          build a team of 4 Characters and take them into battle with you. In
          combat, Characters and enemies will act in a set turn order that is
          determined by their Speed stat. The Speed stat also determines how
          long it takes for them to act again.
        </p>
        <h5>Pre-Combat</h5>
        <p>
          While exploring in the overworld, attacking the roaming enemies or
          getting caught by them will initiate a combat sequence. Depending on
          the condition of how the battle was started, either your team or the
          enemy may suffer from a disadvantage.
        </p>
        <p>
          If you initiate the combat by attacking the enemy with either a Basic
          Attack or Technique, then the battle will commence normally. However,
          if the element of the active character that is initiating the combat
          is one that the enemy is weak against, then your team will start the
          battle with the Weakness state. When starting a battle in this state,
          the enemy’s Toughness bar will be reduced, making it easier to
          Weakness Break them. Essentially, this allows your team to have an
          easier time with dealing damage to the enemy.
        </p>
        <p>
          On the other hand, if an enemy manages to catch you and you fail to
          hit them with either your Basic Attack or Technique ability before
          they make contact, then you will enter combat in the Ambushed state.
          In this state, the enemy will get to act before you do.
        </p>
        <p>
          For more details on enemy elemental weakness and Weakness Break, see
          the “Enemies” section below.
        </p>
        <h5>Player Actions</h5>
        <p>
          During a Character’s turn, they are able to take one of the following
          actions:
        </p>
        <ul>
          <li>
            <strong>Use Basic Attack ability</strong>
          </li>
          <li>
            <strong>Use Skill ability</strong>
          </li>
          <ul>
            <li>Using a Skill ability consumes Skill Point(s).</li>
            <li>
              Skills Points can be generated primarily by using the Battle
              Attack ability.{' '}
            </li>
            <li>
              You can hold up to a maximum of 5 Skill Points and they are shared
              between the whole team.
            </li>
            <li>
              You cannot activate a Skill ability if you do not have any Skill
              Points.
            </li>
          </ul>
          <li>
            <strong>Use Ultimate ability if it is fully charged</strong>
          </li>
          <ul>
            <li>
              Using an Ultimate ability during the Character’s turn does not end
              that Character’s turn. The Character will use the Ultimate ability
              immediately, then you will be able to select another action for
              them to use.{' '}
            </li>
            <li>
              Ultimate abilities can also be used when it is not currently the
              Character’s turn. If used in such a way, then the Character will
              immediately activate their Ultimate ability after the current turn
              ends. This allows you to interrupt the normal turn order flow.
              Multiple Ultimates can also be chained in this way which can lead
              to very explosive out of turn damage.
            </li>
            <li>
              Ultimate abilities cost a certain amount of Energy to activate.
              Energy can be gained via the following actions:
            </li>
            <ul>
              <li>Using Basic Attack ability - Generates 20 Energy</li>
              <li>Using Skill ability - Generates 30 Energy</li>
              <li>Using Ultimate ability - Generates 5 Energy</li>
              <li>Defeating enemies Generates 10 Energy/enemy</li>
              <li>
                Getting hit by enemies in combat - Energy gained will vary
                depending on the enemy
              </li>
              <li>Characters and Light Cones effects</li>
            </ul>
            <li>
              The amount of Energy gained from each of the actions listed above
              can be increased by increasing the Character’s Energy Restoration
              Rate stat.
            </li>
          </ul>
        </ul>
        <h5>Enemies</h5>
        <p>
          Enemies in Honkai: Star Rail have two different bars to represent
          their resilience: HP and Toughness.
        </p>
        <p>
          The <strong>HP bar is the red bar</strong> and represents how much
          damage they can take before falling in combat. The HP bar goes down
          whenever the unit takes damage.
        </p>
        <p>
          On the other hand, <strong>the Toughness bar (the white bar)</strong>{' '}
          can only be damaged by attacks of the elements that the enemy is weak
          to.
        </p>
        <p>
          Enemies have a set of elemental weaknesses (generally 2 or 3) which
          are displayed above their head.{' '}
          <strong>
            Attacking an enemy with an attack of an element that it is weak
            against will reduce its Toughness (the white bat above its HP)
          </strong>
          . You can inflict Weakness Break on an enemy by reducing its Toughness
          to 0. An enemy that has been Weakness Broken will suffer additional
          damage and a debuff based on the element of the attack that reduced
          its Toughness to 0.
        </p>
        <p>The Weakness Break for each element are as follows:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Element</th>
              <th>Damage</th>
              <th>Debuff</th>
              <th>Debuff info</th>
              <th>Is DoT?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="hsr-dmg phys">Physical</span>
              </td>
              <td>Highest</td>
              <td>Bleed</td>
              <td>
                Deals damage over time (based on enemy Max HP) lasting for 2
                turns.
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg fire">Fire</span>
              </td>
              <td>Highest</td>
              <td>Burn</td>
              <td>Deals damage over time lasting for 2 turns.</td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg wind">Wind</span>
              </td>
              <td>High</td>
              <td>Wind Shear</td>
              <td>
                Deals damage over time lasting for 2 turns (can stack up to 5
                times).
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg lightning">Lightning</span>
              </td>
              <td>Average</td>
              <td>Shock</td>
              <td>Deals damage over time lasting for 2 turns.</td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg ice">Ice</span>
              </td>
              <td>Average</td>
              <td>Freeze</td>
              <td>
                Deals damage and Freezes the enemy for 1 turn. After enemy is
                unfrozen, he skips a turn, but advances his action forward by
                50%.
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg quantum">Quantum</span>
              </td>
              <td>Low</td>
              <td>Entanglement</td>
              <td>
                Deals damage (based on enemy max Toughness) and Delays the enemy
                action based on Break Effect stat. Applies 1 stack whenever the
                enemy is hit (up to 5 stacks).
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg imaginary">Imaginary</span>
              </td>
              <td>None</td>
              <td>Imprisonment</td>
              <td>
                Delays the enemy action based on Break Effect stat and reduces
                their Speed by 10% for 1 turn.
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          While broken, the enemy’s Toughness remains at 0 and further attacks
          of an element that the enemy is weak against will have no additional
          effects on it. A broken enemy will recover from its broken state on
          its next turn. Its Toughness will also be replenished back to full and
          it can be Weakness Broken again.
        </p>
        <SectionHeader title="Video" />
        <p>
          If you want to see both exploration and combat in action, check out
          this video:
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="TTs7XDGcLCk" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesCombatandExploPage;

export const Head: React.FC = () => (
  <Seo
    title="Combat and exploration | Honkai: Star Rail | Prydwen Institute"
    description="Information about the combat and exploration in Honkai: Star Rail."
  />
);
